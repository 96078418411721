import { httpClient } from '@connectors/HttpClient'

import type { Curriculum, ObjectiveCategory } from '../types'

export type ObjectiveCategoryParams = Omit<ObjectiveCategory, 'id'>

export type ObjectiveCategoryWithData = {
    curriculum: Curriculum
} & ObjectiveCategory

export const ObjectiveCategoryService = {
    get: async (id: string): Promise<ObjectiveCategory> => {
        const response = await httpClient.axios.get(`/admin/objective-categories/${id}`)
        return response.data
    },
    update: async (id: string, params: Partial<ObjectiveCategoryParams>): Promise<any> => {
        const response = await httpClient.axios.put(`/admin/objective-categories/${id}`, params)
        return response.data
    },
    create: async (params: ObjectiveCategoryParams): Promise<any> => {
        const response = await httpClient.axios.post('/admin/objective-categories', params)
        return response.data
    },
    delete: async (id: string): Promise<any> => {
        const response = await httpClient.axios.delete(`/admin/objective-categories/${id}`)
        return response.data
    }
}
