import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { ConfigProvider } from 'antd'

import { UnauthenticatedLayout } from '@layouts/UnauthenticatedLayout'
import { AuthenticatedLayout } from '@layouts/AuthenticatedLayout'

import { Confirmation } from '@components/Confirmation'
import { Notifications } from '@components/Notifications'
import { initializeSentry } from '@connectors/SentryClient'
import { AuthProvider, ProtectedRoute } from '@hooks/useAuth'

import { CurriculumListPage } from './admin-curriculum/pages/Curriculum/CurriculumListPage'
import { YearLevelListPage } from './admin-curriculum/pages/YearLevel/YearLevelListPage'
import { BuilderPage } from './admin-curriculum/pages/CurriculumBuilder/BuilderPage'
import { BundlesPage } from './admin-curriculum/pages/Bundles/BundlesPage'

import { ErrorPage } from './admin-general/pages/Error'
import { DashboardPage } from './admin-general/pages/DashboardPage'
import { LoginPage } from './admin-general/pages/Login'
import { UserDashboardPage } from './admin-users/pages/UserDashboardPage/UserDashboardPage'
import { ReportDashboardPage } from './admin-reports/pages/ReportDashboardPage/ReportDashboardPage'
import { LogoutPage } from './admin-general/pages/Logout'
import { SchoolDashboardPage } from './admin-schools/pages/SchoolDashboardPage/SchoolDashboardPage'
import { UserDetailsPage } from './admin-users/pages/UserDetailsPage/UserDetailsPage'
import { SchoolDetailsPage } from './admin-schools/pages/SchoolDetailsPage/SchoolDetailsPage'
import { ProductDetailsPage } from './admin-products/pages/ProductDetailsPage/ProductDetailsPage'
import { ProductDashboardPage } from './admin-products/pages/ProductDashboardPage/ProductDashboardPage'
import { PublisherDashboardPage } from './admin-publisher/pages/PublisherDashboardPage/PublisherDashboardPage'
import { PublisherDetailsPage } from './admin-publisher/pages/PublihserDetailsPage/PublisherDetailsPage'
import BatchDashboardPage from './admin-batch/pages/DashboardPage/DashboardPage'
import { SettingsDashboardPage } from './admin-settings/pages/SettingsDashboardPage'

// Quill CSS bundles
import 'quill/dist/quill.bubble.css'
import 'quill/dist/quill.snow.css'

if (import.meta.env.VITE_NODE_ENV === 'production') {
    initializeSentry()
}

const router = createBrowserRouter(
    [
        {
            path: '/login',
            element: (
                <UnauthenticatedLayout>
                    <LoginPage />
                </UnauthenticatedLayout>
            ),
            errorElement: <ErrorPage />
        },
        {
            path: '/',
            element: (
                <ProtectedRoute>
                    <AuthenticatedLayout />
                </ProtectedRoute>
            ),
            errorElement: <ErrorPage />,
            children: [
                {
                    path: '/',
                    element: <DashboardPage />
                },
                {
                    path: '/users',
                    element: <UserDashboardPage />
                },
                {
                    path: '/users/:userId',
                    element: <UserDetailsPage />
                },
                {
                    path: '/schools',
                    element: <SchoolDashboardPage />
                },
                {
                    path: '/schools/:id',
                    element: <SchoolDetailsPage />
                },
                {
                    path: '/products',
                    element: <ProductDashboardPage />
                },
                {
                    path: '/products/:id',
                    element: <ProductDetailsPage />
                },
                {
                    path: '/reports',
                    element: <ReportDashboardPage />
                },
                {
                    path: '/schools',
                    element: <SchoolDashboardPage />
                },
                {
                    path: '/curriculums',
                    element: <CurriculumListPage />
                },
                {
                    path: '/year-levels',
                    element: <YearLevelListPage />
                },
                {
                    path: '/curriculum-builder/:curriculumId/:objectiveCategoryId',
                    element: <BuilderPage />
                },
                {
                    path: '/curriculum-builder/:curriculumId',
                    element: <BuilderPage />
                },
                {
                    path: '/curriculum-builder',
                    element: <BuilderPage />
                },
                {
                    path: '/bundles',
                    element: <BundlesPage />
                },
                {
                    path: '/logout',
                    element: <LogoutPage />
                },
                {
                    path: '/publishers',
                    element: <PublisherDashboardPage />
                },
                {
                    path: '/publishers/:id',
                    element: <PublisherDetailsPage />
                },
                {
                    path: '/batch',
                    element: <BatchDashboardPage />
                },
                {
                    path: '/settings',
                    element: <SettingsDashboardPage />
                }
            ]
        },
        {
            path: '/logout',
            element: (
                <UnauthenticatedLayout>
                    <LogoutPage />
                </UnauthenticatedLayout>
            ),
            errorElement: <ErrorPage />
        }
    ],
    {
        basename: '/'
    }
)

const Main = () => {
    return (
        <>
            <Notifications />
            <Confirmation />
            <RouterProvider router={router} />
        </>
    )
}

// Important: since strict mode is enabled in dev env, it double the renders.
// For some reason pixi board is not destroyed properly on second rerndering..
// I disabled strict mode until we figure this out..

// As well: In development mode, React will rethrow errors caught within an error boundary.
// This will result in errors being reported twice to Sentry with the above setup, but this won't occur in the production build.
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
        <AuthProvider>
            <ConfigProvider
                theme={{
                    token: {}
                }}
            >
                <Main />
            </ConfigProvider>
        </AuthProvider>
    </Sentry.ErrorBoundary>
)
