import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Breadcrumb, Divider, Flex, Select, Space, Typography } from 'antd'

import { CurriculumService } from '@services/CurriculumService'

import { BuilderProvider, useBuilder } from './BuilderContext'
import { EditObjectiveCategoryModal } from './modals/EditObjectiveCategoryModal'
import { EditObjectiveModal } from './modals/EditObjectiveModal'
import { CreateObjectiveModal } from './modals/CreateObjectiveModal'
import { CreateObjectiveCategoryModal } from './modals/CreateObjectiveCategoryModal'

import type { Curriculum } from '../../../types'
import { CategoryTable } from './components/CategoryTable'
import { ObjectiveTable } from './components/ObjectiveTable'

export const WrappedBuilder = () => {
    const {
        objectiveCategoryList,
        objectiveList,
        control,
        clearControl,
        setSelectedCurriculumId,
        selectedCurriculumId,
        refetch,
        breadcrumbs,
        clearData
    } = useBuilder()
    const { id, action, resource } = control
    const navigate = useNavigate()

    const [curriculums, setCurriculums] = useState<Curriculum[]>()

    const fetchCurriculum = async () => {
        const response = await CurriculumService.list()
        setCurriculums(response)

        if (response.length > 0) {
            setSelectedCurriculumId(response[0].id)
        }
    }
    useEffect(() => {
        fetchCurriculum()
    }, [])

    if (!selectedCurriculumId) {
        return null
    }

    return (
        <>
            <Typography.Title level={1}>National Curriculum</Typography.Title>
            <div>
                <Flex justify="space-between" align="center">
                    <Breadcrumb items={breadcrumbs} />
                    <Space wrap>
                        <p>Select a curriculum</p>
                        <Select
                            defaultValue={selectedCurriculumId}
                            style={{ minWidth: '200px' }}
                            onChange={cId => {
                                navigate(`/curriculum-builder/${cId}`)
                                clearData()
                                setSelectedCurriculumId(cId)
                            }}
                        >
                            {curriculums?.map(c => (
                                <Select.Option key={c.id} value={c.id}>
                                    {c.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Space>
                </Flex>
            </div>

            <CategoryTable data={objectiveCategoryList} />
            {objectiveList && objectiveList?.length > 0 && <Divider />}
            <ObjectiveTable data={objectiveList} />

            {resource === 'OBJECTIVE_CATEGORY' && action === 'ADD' && (
                <CreateObjectiveCategoryModal
                    isOpen={resource === 'OBJECTIVE_CATEGORY' && action === 'ADD'}
                    curriculumId={selectedCurriculumId}
                    parentId={id}
                    onClose={() => clearControl()}
                    onSuccess={refetch}
                />
            )}
            {resource === 'OBJECTIVE_CATEGORY' && action === 'EDIT' && id && (
                <EditObjectiveCategoryModal
                    selectedId={id}
                    parentId={id}
                    onClose={() => clearControl()}
                    onSuccess={refetch}
                />
            )}
            {resource === 'OBJECTIVE' && action === 'EDIT' && id && (
                <EditObjectiveModal
                    curriculumId={selectedCurriculumId}
                    selectedId={id}
                    onClose={() => clearControl()}
                    onSuccess={refetch}
                />
            )}
            {resource === 'OBJECTIVE' && action === 'ADD' && id && (
                <CreateObjectiveModal
                    isOpen={resource === 'OBJECTIVE' && action === 'ADD'}
                    curriculumId={selectedCurriculumId}
                    objectiveCategoryId={id}
                    onClose={() => clearControl()}
                    onSuccess={refetch}
                />
            )}
        </>
    )
}

export const BuilderPage = () => {
    return (
        <BuilderProvider>
            <WrappedBuilder />
        </BuilderProvider>
    )
}
