import { useEffect, useState } from 'react'
import { Button, Divider, Spin, Table, Typography, type TableProps } from 'antd'
import { type TableRowSelection } from 'antd/es/table/interface'

import { UserService } from '@services/UserService'

import { useConfirmation } from '@store/confirmation'
import { useNotifications } from '@store/notifications'

import type { User } from '../../../../types'
import { PromiseThrottle } from '../utils/PromiseThrottle'

export const BatchUserInviteTab = () => {
    const confirm = useConfirmation()
    const { showNotification } = useNotifications()
    const [users, setUsers] = useState<User[]>()

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

    const limit = 1000 // Set a constant limit per page

    const search = async ({ searchOffset, searchValue }: { searchOffset: number; searchValue: string }) => {
        try {
            const results = await UserService.search({
                offset: searchOffset,
                limit,
                searchValue,
                sortBy: 'lastActiveAt',
                sortOrder: 'desc'
            })

            const filteredUsers = results.users.filter(user => user.status === 'INVITE_NOT_SENT')

            setUsers(filteredUsers)
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('Error retrieving users', e)
        }
    }

    useEffect(() => {
        search({ searchOffset: 0, searchValue: '' })
    }, [])

    const sendInvites = async () => {
        if (
            await confirm({
                title: 'Are you sure you want to send the invites?'
            })
        ) {
            try {
                if (selectedRowKeys) {
                    const promiseThrottle = new PromiseThrottle({ limit: 2 })

                    selectedRowKeys.forEach(async rowKey => {
                        promiseThrottle.add(async () => {
                            try {
                                const foundUser = users?.find(user => user.id === rowKey)
                                if (foundUser) {
                                    await UserService.resendInvite(foundUser.id)
                                }
                            } catch (err) {
                                console.error('Failed to send invite', rowKey)

                                showNotification({
                                    title: 'Error sending request',
                                    type: 'error'
                                })
                            }
                        })
                    })

                    await promiseThrottle.start()

                    showNotification({
                        title: 'Invite requests sent',
                        description: "Please do a browser refresh to see who's left"
                    })
                } else {
                    throw new Error('No users to send')
                }
            } catch (err) {
                showNotification({
                    title: 'Failed to send invite',
                    type: 'error'
                })
            }
        }
    }

    const columns: TableProps<User>['columns'] = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        }
    ]

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }

    const rowSelection: TableRowSelection<User> = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
            {
                key: 'top20',
                text: 'Top 20',
                onSelect: changeableRowKeys => {
                    let newSelectedRowKeys = []
                    newSelectedRowKeys = changeableRowKeys.filter((_, index) => index < 20)
                    setSelectedRowKeys(newSelectedRowKeys)
                }
            },
            {
                key: 'top50',
                text: 'Top 50',
                onSelect: changeableRowKeys => {
                    let newSelectedRowKeys = []
                    newSelectedRowKeys = changeableRowKeys.filter((_, index) => index < 50)
                    setSelectedRowKeys(newSelectedRowKeys)
                }
            }
        ]
    }

    if (!users) return <Spin />

    return (
        <>
            <Button onClick={sendInvites}>Send Invites</Button>

            <Divider />
            <Typography.Paragraph>{users.length} uninvited user(s) in the system.</Typography.Paragraph>
            <Table rowSelection={rowSelection} columns={columns} dataSource={users} rowKey="id" pagination={false} />
        </>
    )
}
