import { Link } from 'react-router-dom'
import { Button, Flex, Table, Typography, type TableProps } from 'antd'

import type { ObjectiveCategory } from '../../../../types'
import { useBuilder } from '../BuilderContext'

type CategoryTableProps = {
    data?: ObjectiveCategory[]
}
export const CategoryTable = ({ data }: CategoryTableProps) => {
    const { objectiveCategory, selectedCurriculumId, setControl } = useBuilder()

    const categoryColumns: TableProps<ObjectiveCategory>['columns'] = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <Link to={`/curriculum-builder/${selectedCurriculumId}/${record.id}`}>{text}</Link>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        setControl({
                            action: 'EDIT',
                            id: record.id,
                            resource: 'OBJECTIVE_CATEGORY'
                        })
                    }}
                >
                    Edit
                </Button>
            )
        }
    ]

    return (
        <>
            <Flex align="baseline" gap="small">
                {objectiveCategory?.name && <Typography.Title level={3}>{objectiveCategory.name}</Typography.Title>}
                {objectiveCategory?.id && (
                    <Button
                        type="link"
                        onClick={() => {
                            setControl({
                                action: 'EDIT',
                                id: objectiveCategory.id,
                                resource: 'OBJECTIVE_CATEGORY'
                            })
                        }}
                    >
                        Edit
                    </Button>
                )}

                <Button
                    type="link"
                    onClick={() => {
                        setControl({
                            action: 'ADD',
                            id: objectiveCategory?.id || null,
                            resource: 'OBJECTIVE_CATEGORY'
                        })
                    }}
                >
                    Add Category
                </Button>
            </Flex>
            {data && data.length > 0 && <Table<ObjectiveCategory> columns={categoryColumns} dataSource={data} />}
        </>
    )
}

CategoryTable.defaultProps = {
    data: undefined
}
