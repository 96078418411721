import { useEffect, useMemo, useState } from 'react'
import { Button, Flex, Space, Table, type TableProps, Typography } from 'antd'

import { useBuilder } from '../BuilderContext'

import type { Objective } from '../../../../types'
import { HtmlViewer } from './HTMLViewer'

type CategoryTableProps = {
    data?: Objective[]
}
export const ObjectiveTable = ({ data }: CategoryTableProps) => {
    const { setControl, objectiveCategory, yearLevels } = useBuilder()

    const [selectedYearLevelId, setSelectedYearLevelId] = useState<string>()

    const filteredObjectives: Objective[] = useMemo(() => {
        if (data && selectedYearLevelId) {
            return data.filter(objective => objective.yearLevelId === selectedYearLevelId)
        }
        return []
    }, [data, selectedYearLevelId])

    useEffect(() => {
        if (yearLevels && yearLevels.length > 0) {
            setSelectedYearLevelId(yearLevels[0].id)
        } else {
            setSelectedYearLevelId(undefined)
        }
    }, [yearLevels])

    const objectiveColumns: TableProps<Objective>['columns'] = [
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: description => <HtmlViewer html={description} />
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="link"
                        onClick={() =>
                            setControl({
                                action: 'EDIT',
                                id: record.id,
                                resource: 'OBJECTIVE'
                            })
                        }
                    >
                        Edit
                    </Button>
                </Space>
            )
        }
    ]

    if (!selectedYearLevelId) {
        return null
    }
    return (
        <>
            <Flex align="baseline" gap="small">
                <Typography.Title level={3}>Objectives</Typography.Title>
                {objectiveCategory?.id && (
                    <Button
                        type="link"
                        onClick={() => {
                            setControl({
                                action: 'ADD',
                                id: objectiveCategory.id,
                                resource: 'OBJECTIVE'
                            })
                        }}
                    >
                        Add Objective
                    </Button>
                )}
            </Flex>
            {yearLevels &&
                yearLevels.map(yearLevel => (
                    <>
                        <Button type="link" onClick={() => setSelectedYearLevelId(yearLevel.id)}>
                            {yearLevel.name}
                        </Button>
                    </>
                ))}
            {filteredObjectives && filteredObjectives.length > 0 && (
                <Table<Objective> columns={objectiveColumns} dataSource={filteredObjectives} />
            )}
        </>
    )
}

ObjectiveTable.defaultProps = {
    data: undefined
}
