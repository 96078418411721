import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import {
    Button,
    Card,
    Col,
    Descriptions,
    type DescriptionsProps,
    Divider,
    Flex,
    Row,
    Spin,
    Tabs,
    Typography
} from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { ProductService } from '@services/ProductService'
import { SubscriptionCard } from '@components/SubscriptionCards'
import { useConfirmation } from '@store/confirmation'

import AddSubscriptionModal from './components/AddSubscriptionModal'
import { EditSubscriptionModal } from './components/EditSubscriptionModal'
import { UserService } from '../../../services/UserService'
import { EditUserModal } from '../../components/EditUserModal'
import type { Product, Subscription, User } from '../../../types'

export const UserDetailsPage = () => {
    const [user, setUser] = useState<User>()
    const [products, setProducts] = useState<Product[]>([])
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [isAddSubscriptionModalOpen, setIsAddSubscriptionModalOpen] = useState(false)
    const [subscriptionToEdit, setSubscriptionToEdit] = useState<Subscription | null>(null)

    const { userId } = useParams()
    const navigate = useNavigate()
    const confirm = useConfirmation()

    const fetch = async () => {
        try {
            const results = await UserService.get(userId!)
            const pResultes = await ProductService.list()
            setUser(results)
            setProducts(pResultes)
        } catch (err) {
            console.error('Error retrieving user information', err)
        }
    }

    const onDeleteSubscription = async (subscriptionId: string) => {
        if (!userId) {
            return
        }
        if (
            await confirm({
                title: 'Are you sure you want to delete this subscription?',
                confirmText: 'Delete'
            })
        ) {
            await UserService.deleteSubscription(userId, subscriptionId)
            fetch()
        }
    }

    useEffect(() => {
        if (userId) {
            fetch()
        }
    }, [userId])

    const closeEditModal = () => {
        setIsEditModalOpen(false)
    }

    const items: DescriptionsProps['items'] = [
        {
            key: '0',
            label: 'ID',
            children: user?.id
        },
        {
            key: '1',
            label: 'Firebase ID',
            children: user?.firebaseId
        },
        {
            key: '2',
            label: 'Email',
            children: user?.email
        },
        {
            key: '3',
            label: 'First Name',
            children: user?.firstName
        },
        {
            key: '4',
            label: 'Last Name',
            children: user?.lastName
        },
        {
            key: '5',
            label: 'Role',
            children: user?.role
        },
        {
            key: '6',
            label: 'User Tag',
            children: user?.userTag
        },
        {
            key: '7',
            label: 'Max Boards',
            children: user?.meta.maxBoards
        },
        {
            key: '8',
            label: 'Currency Type',
            children: user?.meta.currencyType
        },
        {
            key: '9',
            label: 'Is Part of Research Program',
            children: user?.userAgreements?.researchProgram ? 'Yes' : 'No'
        },
        {
            key: '10',
            label: 'Created At',
            children: user?.createdAt ? dayjs(user?.createdAt).format('YYYY-MM-DD HH:mm:ss') : 'N/A'
        },
        {
            key: '11',
            label: 'Updated At',
            children: user?.updatedAt ? dayjs(user?.updatedAt).format('YYYY-MM-DD HH:mm:ss') : 'N/A'
        },
        {
            key: '12',
            label: 'Last Active At',
            children: user?.lastActiveAt ? dayjs(user?.lastActiveAt).format('YYYY-MM-DD HH:mm:ss') : 'N/A'
        }
    ]

    if (!user) {
        return <Spin />
    }

    return (
        <Flex gap="middle" vertical>
            <Typography.Title>
                User {user?.firstName} {user?.lastName}
            </Typography.Title>
            <Tabs style={{ padding: '20px' }}>
                <Tabs.TabPane tab="User Details" key="details">
                    <Descriptions
                        title="User Info"
                        items={items}
                        column={3}
                        extra={<Button onClick={() => setIsEditModalOpen(true)}>Edit</Button>}
                    />
                    <Divider />

                    <Typography.Title level={4}>Schools</Typography.Title>
                    <Flex gap="middle">
                        {user?.schoolUsers?.map(su => {
                            return (
                                <Button key={su.id} onClick={() => navigate(`/schools/${su.school.id}`)}>
                                    {su.school.name}
                                </Button>
                            )
                        })}
                    </Flex>
                    <Typography.Title level={4}>Personal Subscriptions</Typography.Title>
                    <Row gutter={[16, 16]}>
                        {user?.userSubscriptions?.map(({ subscription }) => {
                            if (!subscription) {
                                return null
                            }
                            return (
                                <Col key={subscription.id}>
                                    <SubscriptionCard
                                        subscription={subscription}
                                        onEditSubscription={() => setSubscriptionToEdit(subscription)}
                                        onDeleteSubscription={onDeleteSubscription}
                                    />
                                </Col>
                            )
                        })}
                        <Col>
                            <Card hoverable style={{ width: 300 }} onClick={() => setIsAddSubscriptionModalOpen(true)}>
                                <Typography.Text style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                                    + New Subscription
                                </Typography.Text>
                            </Card>
                        </Col>
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Stats" key="stats">
                    User stats
                    <Divider />
                </Tabs.TabPane>
            </Tabs>
            <EditUserModal isOpen={isEditModalOpen} user={user} onClose={closeEditModal} refetch={fetch} />

            <AddSubscriptionModal
                isOpen={isAddSubscriptionModalOpen}
                user={user}
                products={products}
                onClose={() => setIsAddSubscriptionModalOpen(false)}
                refetch={fetch}
            />
            <EditSubscriptionModal
                isOpen={!!subscriptionToEdit}
                subscription={subscriptionToEdit}
                products={products}
                refetch={fetch}
                onClose={() => setSubscriptionToEdit(null)}
                user={user}
            />
        </Flex>
    )
}
