import { httpClient } from '@connectors/HttpClient'

import type { YearLevel, Curriculum } from '../types'

export type CurriculumParams = Omit<Curriculum, 'id'>

export const CurriculumService = {
    list: async (): Promise<Curriculum[]> => {
        const response = await httpClient.axios.get('/admin/curriculums')
        return response.data
    },
    get: async (id: string): Promise<any> => {
        const response = await httpClient.axios.get(`/admin/curriculums/${id}`)
        return response.data
    },
    getCategories: async (id: string): Promise<any> => {
        const response = await httpClient.axios.get(`/admin/curriculums/${id}/objective-categories`)
        return response.data
    },
    getYearLevels: async (id: string): Promise<YearLevel[]> => {
        const response = await httpClient.axios.get(`/admin/curriculums/${id}/year-levels`)
        return response.data
    },
    update: async (id: string, params: CurriculumParams): Promise<any> => {
        const response = await httpClient.axios.put(`/admin/curriculums/${id}`, params)
        return response.data
    },
    create: async (params: CurriculumParams): Promise<any> => {
        const response = await httpClient.axios.post('/admin/curriculums', params)
        return response.data
    },
    delete: async (id: string): Promise<any> => {
        const response = await httpClient.axios.delete(`/admin/curriculums/${id}`)
        return response.data
    }
}
