import React, { useContext, useEffect, useState } from 'react'

import { ObjectiveCategoryService } from '@services/ObjectiveCategoryService'
import { useParams } from 'react-router-dom'
import { CurriculumService } from '@services/CurriculumService'

import type { Objective, ObjectiveCategory, YearLevel } from '../../../types'

type ControlType = {
    id: string | null
    action: 'EDIT' | 'ADD' | null
    resource: 'OBJECTIVE' | 'OBJECTIVE_CATEGORY' | null
}

interface BuilderContextProps {
    control: ControlType
    setControl: (a: ControlType) => void
    clearControl: () => void
    selectedCurriculumId?: string
    setSelectedCurriculumId: (key: string) => void
    yearLevels?: YearLevel[]
    clearData: () => void
    refetch: () => void
    setSelectedObjectiveCategoryId: (id: string) => void
    objectiveCategoryList?: ObjectiveCategory[]
    objectiveList?: Objective[]
    objectiveCategory?: ObjectiveCategory
    breadcrumbs: {
        title: string
        href?: string
    }[]
}

const BuilderContext = React.createContext<BuilderContextProps | undefined>(undefined)

interface IBuilderProviderProps {
    children: React.ReactNode
}

export const BuilderProvider: React.FC<IBuilderProviderProps> = ({ children }) => {
    const { curriculumId, objectiveCategoryId } = useParams()
    const [control, setControl] = useState<ControlType>({ id: null, action: null, resource: null })
    const [selectedCurriculumId, setSelectedCurriculumId] = useState<string | undefined>(curriculumId)
    const [selectedObjectiveCategoryId, setSelectedObjectiveCategoryId] = useState<string | undefined>(
        objectiveCategoryId
    )

    const [objectiveCategory, setObjectiveCategory] = useState<ObjectiveCategory>()

    const [objectiveCategoryList, setObjectiveCategoryList] = useState<ObjectiveCategory[]>()
    const [objectiveList, setObjectiveList] = useState<Objective[]>()
    const [yearLevels, setYearLevels] = useState<YearLevel[]>()
    const [breadcrumbs, setBreadcrumbs] = useState<BuilderContextProps['breadcrumbs']>([])

    const clearData = () => {
        setSelectedObjectiveCategoryId(undefined)
        setSelectedCurriculumId(undefined)
        setObjectiveCategoryList(undefined)
        setObjectiveList(undefined)
        setObjectiveCategory(undefined)
    }

    const buildBreadcrumbs = () => {
        const items = []
        items.push({
            title: 'Home',
            href: `/curriculum-builder/${selectedCurriculumId}`
        })

        if (objectiveCategory) {
            if (objectiveCategory.parentId) {
                items.push({
                    title: 'Up',
                    href: `/curriculum-builder/${selectedCurriculumId}/${objectiveCategory.parentId}`
                })
            }

            items.push({
                title: objectiveCategory.name
            })
        }

        setBreadcrumbs(items)
    }

    const fetchYearLevels = async () => {
        if (selectedCurriculumId) {
            const yls = await CurriculumService.getYearLevels(selectedCurriculumId)
            setYearLevels(yls)
        }
    }

    const fetchObjectiveCategory = async () => {
        if (selectedObjectiveCategoryId) {
            const oc = await ObjectiveCategoryService.get(selectedObjectiveCategoryId)

            if (oc.child) {
                setObjectiveCategoryList(oc.child)
            }
            setObjectiveList(oc.objectives)

            setObjectiveCategory(oc)
        } else if (selectedCurriculumId) {
            const objectiveCategories = await CurriculumService.getCategories(selectedCurriculumId)
            setObjectiveCategoryList(objectiveCategories)
        }
    }

    useEffect(() => {
        fetchObjectiveCategory()

        buildBreadcrumbs()
    }, [selectedCurriculumId, selectedObjectiveCategoryId])

    useEffect(() => {
        setSelectedObjectiveCategoryId(objectiveCategoryId)
    }, [objectiveCategoryId])

    useEffect(() => {
        fetchYearLevels()
        setSelectedCurriculumId(curriculumId)
    }, [curriculumId])

    useEffect(() => {
        buildBreadcrumbs()
    }, [objectiveCategory])

    const clearControl = () => {
        setControl({
            id: null,
            action: null,
            resource: null
        })
    }

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const passed = {
        control,
        setControl,
        clearControl,
        selectedCurriculumId,
        setSelectedCurriculumId,
        clearData,
        setSelectedObjectiveCategoryId,
        objectiveCategoryList,
        objectiveList,
        objectiveCategory,
        breadcrumbs,
        refetch: fetchObjectiveCategory,
        yearLevels
    }

    return <BuilderContext.Provider value={passed}>{children}</BuilderContext.Provider>
}

export const useBuilder = () => {
    const passed = useContext(BuilderContext)

    if (!passed) {
        throw new Error('useLessonResource improperly setup')
    }

    const {
        control,
        setControl,
        clearControl,
        selectedCurriculumId,
        setSelectedCurriculumId,
        clearData,
        refetch,
        setSelectedObjectiveCategoryId,
        objectiveCategoryList,
        objectiveList,
        objectiveCategory,
        breadcrumbs,
        yearLevels
    } = passed

    return {
        control,
        setControl,
        clearControl,
        selectedCurriculumId,
        setSelectedCurriculumId,
        clearData,
        setSelectedObjectiveCategoryId,
        objectiveCategoryList,
        objectiveList,
        objectiveCategory,
        breadcrumbs,
        refetch,
        yearLevels
    }
}
